export * from './authorization.service';
import { AuthorizationService } from './authorization.service';
export * from './authorization.serviceInterface';
export * from './authorizationPassword.service';
import { AuthorizationPasswordService } from './authorizationPassword.service';
export * from './authorizationPassword.serviceInterface';
export * from './calculation.service';
import { CalculationService } from './calculation.service';
export * from './calculation.serviceInterface';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './company.serviceInterface';
export * from './companyLicence.service';
import { CompanyLicenceService } from './companyLicence.service';
export * from './companyLicence.serviceInterface';
export * from './currentEvents.service';
import { CurrentEventsService } from './currentEvents.service';
export * from './currentEvents.serviceInterface';
export * from './export.service';
import { ExportService } from './export.service';
export * from './export.serviceInterface';
export * from './finHistory.service';
import { FinHistoryService } from './finHistory.service';
export * from './finHistory.serviceInterface';
export * from './finMath.service';
import { FinMathService } from './finMath.service';
export * from './finMath.serviceInterface';
export * from './finMortgageInvestment.service';
import { FinMortgageInvestmentService } from './finMortgageInvestment.service';
export * from './finMortgageInvestment.serviceInterface';
export * from './finProbability.service';
import { FinProbabilityService } from './finProbability.service';
export * from './finProbability.serviceInterface';
export * from './finRealEstate.service';
import { FinRealEstateService } from './finRealEstate.service';
export * from './finRealEstate.serviceInterface';
export * from './flag.service';
import { FlagService } from './flag.service';
export * from './flag.serviceInterface';
export * from './import.service';
import { ImportService } from './import.service';
export * from './import.serviceInterface';
export * from './language.service';
import { LanguageService } from './language.service';
export * from './language.serviceInterface';
export * from './log.service';
import { LogService } from './log.service';
export * from './log.serviceInterface';
export * from './role.service';
import { RoleService } from './role.service';
export * from './role.serviceInterface';
export * from './stripe.service';
import { StripeService } from './stripe.service';
export * from './stripe.serviceInterface';
export * from './type.service';
import { TypeService } from './type.service';
export * from './type.serviceInterface';
export * from './user.service';
import { UserService } from './user.service';
export * from './user.serviceInterface';
export * from './video.service';
import { VideoService } from './video.service';
export * from './video.serviceInterface';
export const APIS = [AuthorizationService, AuthorizationPasswordService, CalculationService, CompanyService, CompanyLicenceService, CurrentEventsService, ExportService, FinHistoryService, FinMathService, FinMortgageInvestmentService, FinProbabilityService, FinRealEstateService, FlagService, ImportService, LanguageService, LogService, RoleService, StripeService, TypeService, UserService, VideoService];
