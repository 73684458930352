/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BaseFilter } from '../model/models';
import { CreateGroupRequest } from '../model/models';
import { CreateItemRequest } from '../model/models';
import { CreateSectionRequest } from '../model/models';
import { GroupResource } from '../model/models';
import { ItemResource } from '../model/models';
import { SectionResource } from '../model/models';
import { UpdateGroupRequest } from '../model/models';
import { UpdateItemRequest } from '../model/models';
import { UpdateSectionRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CurrentEventsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Saves new group to database
     * 
     * @param createGroupRequest 
     */
    createNewGroup(createGroupRequest?: CreateGroupRequest, extraHttpRequestParams?: any): Observable<GroupResource>;

    /**
     * Saves new item to database
     * 
     * @param createItemRequest 
     */
    createNewItem(createItemRequest?: CreateItemRequest, extraHttpRequestParams?: any): Observable<ItemResource>;

    /**
     * Saves new section to database
     * 
     * @param createSectionRequest 
     */
    createNewSection(createSectionRequest?: CreateSectionRequest, extraHttpRequestParams?: any): Observable<SectionResource>;

    /**
     * Deletes group with given by ID
     * 
     * @param id 
     */
    deleteGroup(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Deletes item with given by ID
     * 
     * @param id 
     */
    deleteItem(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Deletes section with given by ID
     * 
     * @param id 
     */
    deleteSection(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Get all events
     * 
     * @param filter Get events by filter
     */
    getAllEvents(filter?: BaseFilter, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Find event by given ID
     * 
     * @param id 
     */
    getEventById(id: string, extraHttpRequestParams?: any): Observable<ItemResource>;

    /**
     * Updates an existing group
     * 
     * @param id 
     * @param updateGroupRequest 
     */
    updateGroup(id: string, updateGroupRequest?: UpdateGroupRequest, extraHttpRequestParams?: any): Observable<GroupResource>;

    /**
     * Updates an existing item
     * 
     * @param id 
     * @param updateItemRequest 
     */
    updateItem(id: string, updateItemRequest?: UpdateItemRequest, extraHttpRequestParams?: any): Observable<ItemResource>;

    /**
     * Updates an existing section
     * 
     * @param id 
     * @param updateSectionRequest 
     */
    updateSection(id: string, updateSectionRequest?: UpdateSectionRequest, extraHttpRequestParams?: any): Observable<SectionResource>;

}
